import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  DialogActions,
  Box,
  Divider,
} from "@mui/material";

const InfoDialog = ({ open, handleClose, isLegalChat, isStatuteSearch }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          padding: { xs: "8px 12px", md: "16px 24px" },
          color: "#303f9f",
          fontWeight: "bold",
          textAlign: "center",
          fontSize: { xs: "16px", md: "18px" },
        }}
      >
        {isLegalChat ? "Chat Modes" : "Search Information"}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: { xs: "8px 12px", md: "20px 24px" },
          overflowY: "auto",
          scrollBehavior: "smooth",
          "::-webkit-scrollbar": {
            width: "5px",
          },
          "::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#bbb",
            borderRadius: "6px",
          },
        }}
      >
        {isLegalChat ? (
          <>
            {[
              {
                title: "Hybrid Chat",
                description:
                  "Hybrid Chat combines laws and case precedents to provide relevant legal advice.",
                example:
                  "What are the legal steps I can take if my bank account has been frozen?",
              },
              {
                title: "Statute Chat",
                description:
                  "Statute Chat helps you understand complex legal concepts and connects your questions to relevant laws.",
                example: "What are the data privacy laws in Pakistan?",
              },
              {
                title: "Case Chat",
                description:
                  "Case Chat offers key case law and legal precedents with clear explanations.",
                example:
                  "What are important cases about pollution and companies?",
              },
            ].map((item, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography
                  color="#303f9f"
                  sx={{ fontSize: { xs: "14px", md: "16px" } }}
                  fontWeight="bold"
                  gutterBottom
                >
                  {item.title}
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{
                    mb: 1,
                    lineHeight: 1.6,
                    fontSize: { xs: "13px", md: "14px" },
                  }}
                >
                  {item.description}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#f9f9f9",
                    padding: "8px",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#303f9f",
                      fontSize: { xs: "13px", md: "14px" },
                    }}
                  >
                    Example:
                  </Typography>
                  <Typography
                    sx={{ ml: 1, fontSize: { xs: "13px", md: "14px" } }}
                    color="text.primary"
                  >
                    {item.example}
                  </Typography>
                </Box>
                {index < 2 && (
                  <Divider sx={{ my: 3, backgroundColor: "#e0e0e0" }} />
                )}
              </Box>
            ))}
          </>
        ) : (
          <>
            <Typography
              color="#303f9f"
              sx={{ fontSize: { xs: "14px", md: "16px" } }}
              fontWeight="bold"
              gutterBottom
            >
              Keyword Search
            </Typography>
            <Typography sx={{ fontSize: { xs: "13px", md: "14px" } }}>
              LawGPT enables you to search for specific words and phrases within
              the original judgment text or titles. By default, the search
              results match the exact words of your query with those in the
              original judgment.
            </Typography>

            <Typography
              sx={{ fontSize: { xs: "13px", md: "14px" }, fontWeight: "bold" }}
              color="#303f9f"
              mt="6px"
              gutterBottom
            >
              Refining Your <span>{isStatuteSearch ? "Statute" : "Legal"}</span>{" "}
              Search Queries:
            </Typography>
            <Typography component="ul" sx={{ paddingLeft: "20px" }}>
              <Typography
                component="li"
                mb="6px"
                sx={{ fontSize: { xs: "13px", md: "14px" } }}
              >
                <strong>ANDD:</strong> Enter "arrest ANDD bail" to return
                judgments that include both "arrest" and "bail."
              </Typography>
              <Typography
                component="li"
                mb="6px"
                sx={{ fontSize: { xs: "13px", md: "14px" } }}
              >
                <strong>ORR:</strong> Use "damages ORR compensation" to display
                judgments containing either "damages" or "compensation."
              </Typography>
              <Typography
                component="li"
                mb="6px"
                sx={{ fontSize: { xs: "13px", md: "14px" } }}
              >
                <strong>NOTT:</strong> Search for "copyright NOTT patent" to
                include "copyright" while excluding "patent."
              </Typography>
              <Typography
                component="li"
                mb="6px"
                sx={{ fontSize: { xs: "13px", md: "14px" } }}
              >
                <strong>Parentheses ( ): </strong> Use parentheses, e.g., "loss
                AND (economic ORR physical)" to return judgments with either
                term.
              </Typography>
              <Typography
                component="li"
                mb="6px"
                sx={{ fontSize: { xs: "13px", md: "14px" } }}
              >
                <strong>Quotation Marks " ": </strong> Search for "institutional
                investors" to get judgments with the exact phrase.
              </Typography>
              <Typography
                component="li"
                mb="6px"
                sx={{ fontSize: { xs: "13px", md: "14px" } }}
              >
                <strong>Asterisk (*): </strong> Search with a wildcard like
                "constitut*" to return words like "constitution" or
                "constitutes."
              </Typography>
            </Typography>
            {!isStatuteSearch && (
              <>
                <Typography
                  color="#303f9f"
                  sx={{ fontSize: { xs: "14px", md: "16px" } }}
                  fontWeight="bold"
                  gutterBottom
                >
                  AI Search
                </Typography>
                <Typography
                  paragraph
                  sx={{ fontSize: { xs: "13px", md: "14px" } }}
                >
                  AI search helps you find information quickly and effectively.
                  Instead of just searching for exact words, it understands the
                  meaning behind your query.
                </Typography>
                <Typography
                  paragraph
                  sx={{ fontSize: { xs: "13px", md: "14px" } }}
                >
                  <strong>Understanding Your Query:</strong> AI search analyzes
                  what you're looking for by focusing on the overall meaning
                  rather than specific words.
                </Typography>
                <Typography
                  paragraph
                  sx={{ fontSize: { xs: "13px", md: "14px" } }}
                >
                  <strong>Matching with Judgments:</strong> The AI compares your
                  query with judgment texts, finding relevant information even
                  if the exact words don’t match.
                </Typography>
                <Typography
                  paragraph
                  sx={{ fontSize: { xs: "13px", md: "14px" } }}
                >
                  <strong>Finding Relevant Results:</strong> AI search delivers
                  results that better match your question, making it easier to
                  find the information you need.
                </Typography>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
          sx={{
            borderRadius: 30,
            px: { xs: 2, md: 4 },
            py: { xs: 1, md: 1.5 },
            justifyContent: "center",
            marginBottom: "8px",
            textTransform: "none",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;

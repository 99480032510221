import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import StatuteDetailSidebar from "./layout/StatuteDetailSidebar";
import StatuteDetail from "./StatuteDetail";
import AskChatbot from "./layout/AskChatbot";

const StatuteDetailDialog = ({ open, onClose }) => {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const statuteSections = useSelector((state) => state.statuteSections);
  const { sections } = statuteSections;

  const [selectedStatuteSection, setSelectedStatuteSection] = useState(0);
  const [statuteContent, setStatuteContent] = useState("");
  const [openAskChatbot, setOpenAskChatbot] = useState(false);

  const toggleAskChatbot = () => {
    setOpenAskChatbot((prev) => !prev);
  };

  const handleStatuteSectionClick = (section) => {
    setSelectedStatuteSection(section);
  };

  useEffect(() => {
    if (sections?.length > 0) {
      const context = sections?.[0]?.related_data
        ?.map((section) => section.Statute)
        .join(", ");
      setStatuteContent(context);
    }
  }, [sections]);

  if (!open) return null;
  return (
    <Box
      sx={{
        position: "absolute",
        width: { xs: "100%", lg: "calc(100% - 45px)" },
        height: "calc(100vh - 50px)",
        top: "50px",
        left: { xs: "0px", lg: "45px" },
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1, // Higher z-index to overlay on top of other elements
      }}
      onClick={onClose}
    >
      {/* Content container */}
      <Box
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the content
        sx={{
          position: "relative",
          width: { xs: "100vw", lg: "calc(100vw - 45px)" },
          height: "calc(100vh - 50px)",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          overflowY: "clip",
        }}
      >
        <StatuteDetailSidebar
          onSectionClick={handleStatuteSectionClick}
          selectedSection={selectedStatuteSection}
          onClose={onClose}
        />
        <StatuteDetail
          toggleAskChatbot={toggleAskChatbot}
          closeOnBack={onClose}
          selectedSection={selectedStatuteSection}
        />
        {isMobileView ? (
          <Drawer
            anchor="right"
            open={openAskChatbot}
            ModalProps={{
              keepMounted: true,
            }}
            onClose={toggleAskChatbot}
          >
            <AskChatbot chatContext={statuteContent} component={"statute"} />
          </Drawer>
        ) : (
          <AskChatbot chatContext={statuteContent} component={"statute"} />
        )}
      </Box>
    </Box>
  );
};

export default StatuteDetailDialog;

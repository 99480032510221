import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import CaseDetail from "./CaseDetail";
import AskChatbot from "./layout/AskChatbot";
import { getOriginalJudgement } from "../utils/HelperFunction/getOriginalJudgement";

const CaseDetailDialog = ({ open, onClose }) => {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const caseDetail = useSelector((state) => state.caseDetail);
  const { caseItem } = caseDetail;

  const [openAskChatbot, setOpenAskChatbot] = useState(false);

  const toggleAskChatbot = () => {
    setOpenAskChatbot((prev) => !prev);
  };

  const getAppellant = (title) => {
    return title ? title.split("Versus")[0] : "";
  };

  const getRespondents = (title) => {
    return title ? title.split("Versus")[1] : "";
  };

  const caseContentWithMetadata = `
  Title: ${caseItem?.Title || caseItem?.metadata?.Title}
  Citation: ${caseItem?.Citation_Name || caseItem?.metadata?.Citation_Name}
  Court: ${caseItem?.Court || caseItem?.metadata?.Court}
  Judge: ${caseItem?.judge_name_clean || caseItem?.metadata?.Judge_Name}
  Appellant: ${
    getAppellant(caseItem?.Title) || getAppellant(caseItem?.metadata?.Title)
  }
  Respondents: ${
    getRespondents(caseItem?.Title) || getRespondents(caseItem?.metadata?.Title)
  }
  ${getOriginalJudgement(caseItem?.Case_Description)}
`;

  if (!open) return null;
  return (
    <Box
      sx={{
        position: "absolute",
        width: { xs: "100%", lg: "calc(100% - 45px)" },
        height: "calc(100vh - 50px)",
        top: "50px",
        left: { xs: "0px", lg: "45px" },
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
      onClick={onClose}
    >
      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{
          position: "relative",
          width: { xs: "100vw", lg: "calc(100vw - 45px)" },
          height: "calc(100vh - 50px)",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          overflowY: "clip",
        }}
      >
        <CaseDetail toggleAskChatbot={toggleAskChatbot} closeOnBack={onClose} />
        {isMobileView ? (
          <Drawer
            anchor="right"
            open={openAskChatbot}
            ModalProps={{
              keepMounted: true,
            }}
            onClose={toggleAskChatbot}
          >
            <AskChatbot
              toggleAskChatbot={toggleAskChatbot}
              chatContext={caseContentWithMetadata}
              component={"case"}
            />
          </Drawer>
        ) : (
          <AskChatbot
            chatContext={caseContentWithMetadata}
            component={"case"}
          />
        )}
      </Box>
    </Box>
  );
};

export default CaseDetailDialog;

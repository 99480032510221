import React from "react";
import {
  Box,
  List,
  ListItemText,
  Typography,
  ListItemButton,
  Button,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const CaseSummarySidebar = ({
  onSectionClick,
  selectedSection,
  closeOnBack,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: { xs: "none", lg: "flex" },
        width: "278px",
        overflowY: "auto",
        height: "100%",
        boxSizing: "border-box",
        bgcolor: "#f5f5f5",
        p: "12px 4px 8px 12px",
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#101936",
          borderRadius: "6px",
        },
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          borderRadius: "8px",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            padding: "8px",
            borderBottom: "1px solid #e6e6e6",
            mb: 1,
          }}
        >
          <IconButton sx={{ color: "#303f9f" }} onClick={closeOnBack}>
            <FontAwesomeIcon icon={faCircleArrowLeft} fontSize="23px" />
          </IconButton>
          <Typography
            sx={{
              fontSize: "18px",
              color: "#303f9f",
              fontWeight: "bold",
            }}
          >
            Table of Contents
          </Typography>
        </Box>
        <List sx={{ paddingTop: 0 }}>
          <ListItemButton
            sx={{
              pl: 2,
              pt: "4px",
              pb: "4px",
              backgroundColor:
                selectedSection === "case-information" ? "#EBF5FF" : "",
              borderLeft:
                selectedSection === "case-information"
                  ? "2px solid #303f9f"
                  : "",
              "&:hover": {
                backgroundColor: "#EBF5FF",
              },
            }}
            onClick={() => onSectionClick("case-information")}
          >
            <ListItemText
              primary="Case Information"
              primaryTypographyProps={{ fontSize: "14px" }}
            />
          </ListItemButton>
          <ListItemButton
            sx={{
              pl: 2,
              pt: "4px",
              pb: "4px",
              backgroundColor:
                selectedSection === "legal-provisions" ? "#EBF5FF" : "",
              borderLeft:
                selectedSection === "legal-provisions"
                  ? "2px solid #303f9f"
                  : "",
              "&:hover": {
                backgroundColor: "#EBF5FF",
              },
            }}
            onClick={() => onSectionClick("legal-provisions")}
          >
            <ListItemText
              primary="Legal Provisions"
              primaryTypographyProps={{ fontSize: "14px" }}
            />
          </ListItemButton>
          <ListItemButton
            sx={{
              pl: 2,
              pt: "4px",
              pb: "4px",
              backgroundColor:
                selectedSection === "case-summary" ? "#EBF5FF" : "",
              borderLeft:
                selectedSection === "case-summary" ? "2px solid #303f9f" : "",
              "&:hover": {
                backgroundColor: "#EBF5FF",
              },
            }}
            onClick={() => onSectionClick("case-summary")}
          >
            <ListItemText
              primary="Summary"
              primaryTypographyProps={{ fontSize: "14px" }}
            />
          </ListItemButton>
          <ListItemButton
            sx={{
              pl: 2,
              pt: "4px",
              pb: "4px",
              backgroundColor:
                selectedSection === `courts-findings` ? "#EBF5FF" : "",
              borderLeft:
                selectedSection === "courts-findings"
                  ? "2px solid #303f9f"
                  : "",
              "&:hover": {
                backgroundColor: "#EBF5FF",
              },
            }}
            onClick={() => onSectionClick(`courts-findings`)}
          >
            <ListItemText
              primary="Court's Findings"
              primaryTypographyProps={{ fontSize: "14px" }}
            />
          </ListItemButton>
          <ListItemButton
            sx={{
              pl: 2,
              pt: "4px",
              pb: "4px",
              backgroundColor:
                selectedSection === "case-law-references" ? "#EBF5FF" : "",
              borderLeft:
                selectedSection === "case-law-references"
                  ? "2px solid #303f9f"
                  : "",
              "&:hover": {
                backgroundColor: "#EBF5FF",
              },
            }}
            onClick={() => onSectionClick("case-law-references")}
          >
            <ListItemText
              primary="Case Law References"
              primaryTypographyProps={{ fontSize: "14px" }}
            />
          </ListItemButton>
        </List>
      </Box>
    </Box>
  );
};

export default CaseSummarySidebar;

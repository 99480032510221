import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Box, Stack, Drawer, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import Sidebar from "./components/layout/Sidebar";
import ChatInterface from "./components/ChatInterface";
import { initializeGA, trackPageView } from "./analytics";
import NotFound from "./components/NotFound";
import LegalSearchSidebar from "./components/layout/LegalSearchSidebar";
import SearchResults from "./components/SearchResults";
import HomeSidebar from "./components/layout/HomeSidebar";
import StatuteSearch from "./components/StatuteSearch";
import Bookmarks from "./pages/Bookmarks";
import LegalDocumentSidebar from "./components/layout/LegalDocumentSidebar";
import DocumentAnalysis from "./components/DocumentAnalysis";
import StatuteSearchSidebar from "./components/layout/StatuteSearchSidebar";
import Header from "./components/layout/Header";
import LegalLibrary from "./components/LegalLibrary";
import ApplicationDrafting from "./components/ApplicationDrafting";
import PetitionDrafting from "./components/PetitionDrafting";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import CaseManagmentSystem from "./pages/CaseManagmentSystem/index";
import InsufficientCreditsModal from "./components/Modal/InsufficientCreditsModal";
import {
  hasSeenCreditsModal,
  setCredits,
  setHasSeenLowCreditAlert,
  showLowCreditModal,
} from "./actions/creditActions";
import CreditAlertModal from "./components/Modal/CreditAlertModal";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import SubscriptionPlans from "./pages/Subscriptions";
import { TutorialVideos } from "./pages/Tutorial Videos";
const queryClient = new QueryClient();
initializeGA();
const baseUrl = process.env.REACT_APP_API_BASE_URL;

function App() {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const location = useLocation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [caseLawSearchQuery, setCaseLawSearchQuery] = useState("");
  const [statuteSearchQuery, setStatuteSearchQuery] = useState("");
  const [selectedStatuteLetterFilter, setSelectedStatuteLetterFilter] =
    useState("");
  const [selectedStatuteTypeFilter, setSelectedStatuteTypeFilter] =
    useState("");
  const [selectedStatuteProvinceFilter, setSelectedStatuteProvinceFilter] =
    useState("");
  const [selectedStatuteYear, setSelectedStatuteYear] = useState("");
  const [statuteCurrentPage, setStatuteCurrentPage] = useState(1);
  const [statuteResultsCount, setStatuteResultsCount] = useState(10);
  const [statuteSearchMode, setStatuteSearchMode] = useState("Title");
  const [caseLawCurrentPage, setCaseLawCurrentPage] = useState(1);
  const [isNewCaseLawSearch, setIsNewCaseLawSearch] = useState(false);
  const [caseLawSearchMode, setCaseLawSearchMode] = useState("keyword");
  const [caseLawSortBy, setCaseLawSortBy] = useState("Most Relevant");
  const [caseLawResultsCount, setCaseLawResultsCount] = useState(10);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const dispatch = useDispatch();
  const { credits, hasSeenLowCreditAlert, isCreditsInitialized } = useSelector(
    (state) => state.credit
  );

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

  useEffect(() => {
    if (isCreditsInitialized) {
      if (credits < 10 && !hasSeenLowCreditAlert) {
        dispatch(showLowCreditModal(true));
      } else if (credits >= 10) {
        dispatch(setHasSeenLowCreditAlert(false));
      }
    }
  }, [credits, hasSeenLowCreditAlert, isCreditsInitialized, dispatch]);

  const updateResultsCount = (value) => {
    setStatuteResultsCount(value);
  };

  const updateSelectedStatuteLetterFilter = (letter) => {
    setSelectedStatuteLetterFilter(letter);
  };

  const toggleSidebarVisibility = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    trackPageView(location.pathname + location.search);

    return () => {
      setSelectedStatuteLetterFilter("");
      setSelectedStatuteProvinceFilter("");
      setSelectedStatuteTypeFilter("");
    };
  }, [location]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (!userInfo?._id) {
          console.warn("User ID not available.");
          return;
        }

        const response = await axios.get(`${baseUrl}/getUserProfile`, {
          params: { userId: userInfo._id },
        });

        if (response.status === 200) {
          const data = response.data;
          dispatch({
            type: "USER_LOGIN_SUCCESS",
            payload: data,
          });
          dispatch(
            setCredits(
              data.credits,
              data._id,
              data.creditExpiration,
              data.creditRequestCount
            )
          );

          dispatch(hasSeenCreditsModal(data.hasSeenModal));
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, [dispatch, userInfo?._id]);
  return userInfo ? (
    <QueryClientProvider client={queryClient}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Header handleSidebarToggle={toggleSidebar} />
        <ToastContainer />
        {/* <div className="App">
          <TawkMessengerReact
            propertyId="676950dfaf5bfec1dbe0a68c"
            widgetId="1ifpm7q9d"
          />
        </div> */}
        <Stack
          sx={{ marginLeft: { xs: "0", lg: "45px" } }}
          direction="row"
          height="calc(100vh - 50px)"
        >
          {isMobileView && location.pathname === "/app/legal-chat" && (
            <Drawer
              anchor="left"
              open={isSidebarVisible}
              ModalProps={{
                keepMounted: true,
              }}
              onClose={toggleSidebarVisibility}
            >
              <Sidebar closeSidebar={toggleSidebarVisibility} />
            </Drawer>
          )}
          <HomeSidebar
            isOpen={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
          />
          {!isMobileView && location.pathname === "/app/legal-chat" && (
            <Sidebar />
          )}
          {/* Commented out temporarily */}
          {/* {location.pathname === "/app/document-analysis" && (
            <LegalDocumentSidebar />
          )} */}
          {isMobileView && location.pathname === "/app/searchResults" && (
            <Drawer
              anchor="left"
              open={isSidebarVisible}
              onClose={toggleSidebarVisibility}
            >
              <LegalSearchSidebar
                toggleSidebar={toggleSidebarVisibility}
                sideBarState={isSidebarVisible}
                searchKeyword={caseLawSearchQuery}
                currentPage={caseLawCurrentPage}
                setCurrentPage={setCaseLawCurrentPage}
                isNewCaseLawSearch={isNewCaseLawSearch}
                setIsNewCaseLawSearch={setIsNewCaseLawSearch}
                searchMode={caseLawSearchMode}
                sortBy={caseLawSortBy}
                resultsCount={caseLawResultsCount}
              />
            </Drawer>
          )}

          {!isMobileView && location.pathname === "/app/searchResults" && (
            <LegalSearchSidebar
              searchKeyword={caseLawSearchQuery}
              currentPage={caseLawCurrentPage}
              setCurrentPage={setCaseLawCurrentPage}
              isNewCaseLawSearch={isNewCaseLawSearch}
              setIsNewCaseLawSearch={setIsNewCaseLawSearch}
              searchMode={caseLawSearchMode}
              sortBy={caseLawSortBy}
              resultsCount={caseLawResultsCount}
            />
          )}
          {location.pathname === "/app/legal-chat" && (
            <ChatInterface toggleSidebar={toggleSidebarVisibility} />
          )}
          {location.pathname === "/app/case-management" && <NotFound />}
          {location.pathname === "/app/petition-drafting" && (
            <PetitionDrafting />
          )}
          {location.pathname === "/app/application-drafting" && (
            <ApplicationDrafting />
          )}
          {location.pathname === "/app/document-analysis" && (
            <DocumentAnalysis />
          )}
          {location.pathname === "/app/legal-library" && <LegalLibrary />}
          {location.pathname === "/app/bookmarks" && <Bookmarks />}
          {location.pathname === "/app/searchResults" && (
            <SearchResults
              toggleSidebar={toggleSidebarVisibility}
              searchKeyword={caseLawSearchQuery}
              setSearchKeyword={setCaseLawSearchQuery}
              currentPage={caseLawCurrentPage}
              setCurrentPage={setCaseLawCurrentPage}
              setIsNewCaseLawSearch={setIsNewCaseLawSearch}
              searchMode={caseLawSearchMode}
              updateSearchMode={setCaseLawSearchMode}
              sortBy={caseLawSortBy}
              updateSortBy={setCaseLawSortBy}
              resultsCount={caseLawResultsCount}
              updateResultsCount={setCaseLawResultsCount}
            />
          )}
          {!isMobileView && location.pathname === "/app/statute-search" && (
            <StatuteSearchSidebar
              selectedLetter={selectedStatuteLetterFilter}
              updateSelectedLetter={updateSelectedStatuteLetterFilter}
              selectedYear={selectedStatuteYear}
              updateSelectedYear={setSelectedStatuteYear}
              resultsCount={statuteResultsCount}
              currentPage={statuteCurrentPage}
              updateCurrentPage={setStatuteCurrentPage}
              searchMode={statuteSearchMode}
              statuteSearchValue={statuteSearchQuery}
              selectedStatuteTypeFilter={selectedStatuteTypeFilter}
              selectedStatuteProvinceFilter={selectedStatuteProvinceFilter}
              setSelectedStatuteTypeFilter={setSelectedStatuteTypeFilter}
              setSelectedStatuteProvinceFilter={
                setSelectedStatuteProvinceFilter
              }
            />
          )}
          {isMobileView && location.pathname === "/app/statute-search" && (
            <Drawer
              anchor="left"
              open={isSidebarVisible}
              onClose={toggleSidebarVisibility}
            >
              <StatuteSearchSidebar
                isSidebarVisible={isSidebarVisible}
                toggleSidebar={toggleSidebarVisibility}
                selectedLetter={selectedStatuteLetterFilter}
                updateSelectedLetter={updateSelectedStatuteLetterFilter}
                selectedYear={selectedStatuteYear}
                updateSelectedYear={setSelectedStatuteYear}
                resultsCount={statuteResultsCount}
                currentPage={statuteCurrentPage}
                updateCurrentPage={setStatuteCurrentPage}
                searchMode={statuteSearchMode}
                statuteSearchValue={statuteSearchQuery}
                selectedStatuteTypeFilter={selectedStatuteTypeFilter}
                selectedStatuteProvinceFilter={selectedStatuteProvinceFilter}
                setSelectedStatuteTypeFilter={setSelectedStatuteTypeFilter}
                setSelectedStatuteProvinceFilter={
                  setSelectedStatuteProvinceFilter
                }
              />
            </Drawer>
          )}
          {location.pathname === "/app/statute-search" && (
            <StatuteSearch
              toggleSidebar={toggleSidebarVisibility}
              selectedYear={selectedStatuteYear}
              updateSelectedYear={setSelectedStatuteYear}
              selectedLetter={selectedStatuteLetterFilter}
              updateSelectedLetter={updateSelectedStatuteLetterFilter}
              searchMode={statuteSearchMode}
              updateSearchMode={setStatuteSearchMode}
              resultsCount={statuteResultsCount}
              updateResultsCount={updateResultsCount}
              currentPage={statuteCurrentPage}
              updateCurrentPage={setStatuteCurrentPage}
              statuteSearchValue={statuteSearchQuery}
              updateStatuteSearchValue={setStatuteSearchQuery}
              selectedStatuteTypeFilter={selectedStatuteTypeFilter}
              selectedStatuteProvinceFilter={selectedStatuteProvinceFilter}
              setSelectedStatuteTypeFilter={setSelectedStatuteTypeFilter}
              setSelectedStatuteProvinceFilter={
                setSelectedStatuteProvinceFilter
              }
            />
          )}

          {location.pathname === "/app/case-management-system" && (
            <CaseManagmentSystem />
          )}
          {location.pathname === "/app/subscriptions" && <SubscriptionPlans />}
          {location.pathname === "/app/tutorial-videos" && <TutorialVideos />}
          <CreditAlertModal />
          <InsufficientCreditsModal />
        </Stack>
      </Box>
    </QueryClientProvider>
  ) : (
    <Navigate to="/" />
  );
}

export default App;

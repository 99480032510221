import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const termsSections = [
  {
    heading: "Acceptance of Terms",
    content:
      'These Terms and Conditions ("Terms") govern your access to and use of LawGPT’s services, including AI-powered tools for legal research, case management, and document drafting. By using our website and services, you agree to comply with these Terms. If you do not agree, you may not use our services.',
  },
  {
    heading: "Services Offered",
    content:
      "LawGPT provides innovative AI-based tools to assist users with legal research and documentation. These include features like access to legal databases, AI-powered chatbot assistance, and tools for creating and managing legal documents. While these services aim to improve efficiency and accuracy, they are supplementary and not intended to replace professional legal advice. ",
  },
  {
    heading: "Eligibility to Use",
    content:
      "Our services are available to users who are at least 18 years old or meet the legal age of their jurisdiction. By using LawGPT, you affirm that you have the authority to enter into these Terms and comply with all applicable laws.",
  },
  {
    heading: "Account Creation and User Responsibilities",
    content:
      "To use certain features of LawGPT, you must register an account with accurate and up-to-date information. Users are responsible for maintaining the confidentiality of their login credentials and all activities under their account. Misuse of the platform, such as providing false information or engaging in unauthorized access, may lead to account suspension or termination.",
  },
  {
    heading: "Prohibited Use",
    content:
      "Users agree not to exploit, reverse engineer, or misuse the platform in any way that may harm the service or other users. This includes uploading harmful content, bypassing security protocols, or using automated tools to extract data from the platform without permission. Any violation may result in immediate termination of access.",
  },
  {
    heading: "Limitations of Liability",
    content:
      "LawGPT is designed to assist with legal research and does not guarantee the accuracy, completeness, or applicability of its outputs. Users acknowledge that they bear full responsibility for decisions made based on the platform’s insights and tools. LawGPT will not be held liable for any loss or damages arising from the use of or inability to use its services.",
  },
  {
    heading: "Subscription Plans and Fees",
    content:
      "LawGPT offers both free and premium subscription plans, with advanced features available only to premium users. Premium users must agree to the billing terms provided during the subscription process, including recurring payments. Refunds, if applicable, will be addressed as per our Refund Policy.",
  },
  {
    heading: "Intellectual Property Rights",
    content:
      "All intellectual property, including platform design, AI models, and databases, remains the exclusive property of LawGPT. Users may access and use content within the limits specified in these Terms. Any unauthorized reproduction, redistribution, or commercial use of our services or content is strictly prohibited.",
  },
  {
    heading: "Privacy and Data Security",
    content:
      "LawGPT is committed to protecting user privacy and follows industry-standard practices to safeguard personal and usage data. Details about how we collect, store, and process information are outlined in our Privacy Policy. By using the platform, you consent to these practices.",
  },
  {
    heading: "Termination of Services",
    content:
      "We reserve the right to suspend or terminate your access to LawGPT at any time, with or without prior notice, for violations of these Terms or at our discretion. Users can terminate their account by contacting support, but they remain liable for any outstanding dues.",
  },
  {
    heading: "Amendments to Terms",
    content:
      "LawGPT reserves the right to modify or update these Terms at any time. Changes will be communicated through our platform or via email, and continued use of our services implies acceptance of the revised Terms.",
  },
  {
    heading: "Governing Law and Dispute Resolution",
    content:
      "These Terms are governed by the laws of Pakistan. Any disputes arising out of or related to the use of LawGPT’s services shall be resolved through negotiation, failing which they will be settled under the jurisdiction of courts in Pakistan. ",
  },
];

const TermsDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          padding: { xs: "8px", md: "24px" },
          borderRadius: "8px",
        },
      }}
    >
      {/* Dialog Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle
          sx={{
            padding: { xs: "8px", md: "16px" },
            textAlign: "center",
            flex: 1,
            fontSize: { xs: "16px", md: "18px" },
            fontWeight: "bold",
            color: "#00509e",
          }}
        >
          Terms and Conditions
        </DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 16, color: "#00509e" }}
        >
          <CloseIcon fontSize={isMobile ? "small" : "medium"} />
        </IconButton>
      </Box>

      {/* Dialog Content */}
      <DialogContent
        sx={{
          overflowY: "auto",
          padding: { xs: "8px", md: "20px" },
          scrollBehavior: "smooth",
          "::-webkit-scrollbar": {
            width: "4px",
          },
          "::-webkit-scrollbar-track": {
            background: "#fff",
            borderRadius: "6px",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#bbb",
            borderRadius: "6px",
          },
        }}
      >
        {termsSections.map((section, index) => (
          <Box key={index} sx={{ marginBottom: "16px", textAlign: "left" }}>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "16px" },
                marginBottom: "8px",
                //color: '#00509e',
              }}
            >
              {`${index + 1}. ${section.heading}`}
            </Typography>
            <Typography
              sx={{
                color: "#555",
                fontSize: "13px",
                //lineHeight: '1.6',
                textAlign: "justify",
                //textIndent: '8px',
              }}
            >
              {section.content}
            </Typography>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default TermsDialog;

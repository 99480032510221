import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Checkbox,
  Pagination,
  IconButton,
  TextField,
  CardActions,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { Stack, styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import config from "../config/api";
import { Slide, ToastContainer, toast } from "react-toastify";
import { showInsufficientCreditsModal } from "../actions/creditActions";
import { deductCredits } from "../utils/HelperFunction/deductCredits";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// Custom styled components
const SearchCard = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fff",
  borderRadius: "8px",
  padding: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const ResultContainer = styled(Box)(({ theme }) => ({
  padding: "15px",
  backgroundColor: "#fff",
  flexGrow: 1,
  marginTop: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
}));

const ResultCard = styled(Card)(({ theme }) => ({
  padding: "10px",
  borderRadius: "8px",
  transition: "box-shadow 0.3s ease, transform 0.2s ease",
  "&:hover": {
    boxShadow: "0 6px 14px rgba(0, 0, 0, 0.3)",
    transform: "translateY(-5px)",
  },
}));

const cleanAndFormatTitle = (title) => {
  if (!title) return "";

  // Remove .pdf suffix if present
  let formattedTitle = title.replace(/\.pdf$/i, "");

  // Remove quotes (single or double)
  formattedTitle = formattedTitle.replace(/['"“”‘’]/g, "");

  // Remove leading date formats (e.g., "21.3.2023") or percentage values (e.g., "7.5%")
  formattedTitle = formattedTitle.replace(
    /^(\d{1,2}\.\d{1,2}\.\d{4}|\d+\.\d+%)\s*/,
    ""
  );

  // Remove any leading numbers (e.g., "1960_31_")
  formattedTitle = formattedTitle.replace(/^\d+[_-]?\d*[_-]?/, "");

  // Remove any text inside parentheses if it's a day of the week or a number (e.g., "(Tuesday)", "(02)")
  formattedTitle = formattedTitle.replace(
    /\(\s*(Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|\d+)\s*\)/gi,
    ""
  );

  // Replace underscores and dashes with spaces
  formattedTitle = formattedTitle.replace(/[_-]/g, " ");

  // Check if the title already ends with a year preceded by a comma
  const yearMatch = formattedTitle.match(/,\s?\d{4}$/);

  // If no year at the end, find the year and add a comma before it
  if (!yearMatch) {
    formattedTitle = formattedTitle.replace(/(\d{4})$/, ", $1");
  }

  // Convert to title case, skipping the year part
  formattedTitle = formattedTitle.replace(/\b\w+/g, (word, index) => {
    // Capitalize each word except already capitalized parts or the year
    if (/\d{4}/.test(word)) {
      return word; // Don't change the year
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return formattedTitle.trim();
};

const highlightText = (text, keyword) => {
  if (!keyword) return text;
  const parts = text.split(new RegExp(`(${keyword})`, "gi"));
  return parts.map((part, index) =>
    part.toLowerCase() === keyword.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: "yellow" }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

const LegalLibrary = () => {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  const credits = useSelector((state) => state.credit.credits);
  const userId = useSelector((state) => state.credit.userId);

  const [searchTerm, setSearchTerm] = useState("");
  const [confirmedSearchTerm, setConfirmedSearchTerm] = useState("");
  const [filteredStatutes, setFilteredStatutes] = useState([]);
  const [favSearchResults, setFavSearchResults] = useState([]);
  const [statuteNames, setStatuteNames] = useState([]);
  const [userFavorites, setUserFavorites] = useState([]);
  const [isStatuteNamesLoading, setIsStatuteNamesLoading] = useState(false);
  const [isFavoriteChecked, setIsFavoriteChecked] = useState(false);
  const [hasNoSearchResults, setHasNoSearchResults] = useState(false);
  const [hasNoFavorites, setHasNoFavorites] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingPdf, setLoadingPdf] = useState({});
  const [booksCount, setBooksCount] = useState("");

  const cardsPerPage = isMobileView ? 12 : 16;

  const totalPages = Math.ceil(
    favSearchResults?.length > 0
      ? favSearchResults.length / cardsPerPage
      : filteredStatutes.length > 0
      ? filteredStatutes.length / cardsPerPage
      : booksCount / cardsPerPage
  );
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentStatutes =
    favSearchResults?.length > 0
      ? favSearchResults.slice(indexOfFirstCard, indexOfLastCard)
      : filteredStatutes?.length > 0
      ? filteredStatutes.slice(indexOfFirstCard, indexOfLastCard)
      : statuteNames;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleCheckboxChange = (event) => {
    setIsFavoriteChecked(event.target.checked);
    if (event.target.checked) {
      fetchFavoriteStatutes();
      setCurrentPage(1);
      setSearchTerm("");
      setConfirmedSearchTerm("");
    } else {
      setHasNoFavorites(false);
      setHasNoSearchResults(false);
      setFavSearchResults([]);
      setFilteredStatutes([]);
      setSearchTerm("");
      setConfirmedSearchTerm("");
    }
  };

  const getStatuteNames = async (searchTerm) => {
    const statutesEndpoint = `${config.apiUrl}/legal_library_search`;
    const favoritesEndpoint = `${baseUrl}/getUserFavorites?userId=${userInfo._id}`;
    if (isFavoriteChecked) return;
    try {
      setIsStatuteNamesLoading(true);
      const requestBodyForBookRetrieval = {
        search: searchTerm,
        count: true,
        top: cardsPerPage,
        skip: (currentPage - 1) * cardsPerPage,
      };

      // Fetch statutes and favorites in parallel
      const [statutesResponse, favoritesResponse] = await Promise.all([
        fetch(statutesEndpoint, {
          method: "POST",
          body: JSON.stringify(requestBodyForBookRetrieval),
        }),
        fetch(favoritesEndpoint, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ]);

      if (statutesResponse.ok) {
        const statutesData = await statutesResponse.json();
        if (statutesData?.count === 0) {
          setHasNoSearchResults(true);
          return;
        }

        let favoriteTitles = [];

        if (favoritesResponse.ok) {
          const favoritesData = await favoritesResponse.json();
          favoriteTitles = favoritesData?.map((fav) => fav);
          setUserFavorites(favoriteTitles);
        }

        const updatedStatuteData = statutesData?.result
          .filter((obj) => obj.name !== "...")
          .map((statute) => ({
            originalTitle: statute.name,
            title: cleanAndFormatTitle(statute.name),
            url: statute.url,
            type: statute.type,
            isFavorite: favoriteTitles.includes(statute.name),
          }))
          .sort((a, b) =>
            a.title.localeCompare(b.title, undefined, { sensitivity: "base" })
          );
        setBooksCount(statutesData?.count);
        setStatuteNames(updatedStatuteData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsStatuteNamesLoading(false);
    }
  };

  const fetchFavoriteStatutes = async () => {
    // Handle no favorites
    if (userFavorites?.length === 0) {
      setHasNoFavorites(true);
      return;
    }
    const statutesEndpoint = `${config.apiUrl}/legal_library_search`;
    try {
      setIsStatuteNamesLoading(true);

      // Request body for fetching statutes
      const requestBodyForBookRetrieval = {
        search: "",
        top: -1,
        skip: 0,
        filter: { titles: userFavorites },
      };

      const statutesResponse = await fetch(statutesEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBodyForBookRetrieval),
      });

      if (statutesResponse.ok) {
        const statutesData = await statutesResponse.json();
        const updatedStatuteData = statutesData?.result
          .map((statute) => ({
            originalTitle: statute.name,
            title: cleanAndFormatTitle(statute.name),
            url: statute.url,
            type: statute.type,
            isFavorite: true,
          }))
          .sort((a, b) =>
            a.title.localeCompare(b.title, undefined, { sensitivity: "base" })
          );
        setFilteredStatutes(updatedStatuteData);
      }
    } catch (error) {
      console.error("Error fetching favorite statutes:", error);
    } finally {
      setIsStatuteNamesLoading(false);
    }
  };

  const getBookPdf = async (url) => {
    try {
      const endpoint = `${config.apiUrl}/get_pdf_from_blob_storage`;
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url }),
      });
      if (!response.ok) {
        throw new Error(
          `Failed to fetch PDF: ${response?.status} ${response?.statusText}`
        );
      }
      const bookBlob = await response.blob();
      return bookBlob;
    } catch (error) {
      console.error("Error fetching PDF:", error.message);
      return null;
    }
  };

  const toggleFavorite = async (statuteItem) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal());
      return;
    }
    const endpoint = statuteItem.isFavorite
      ? `${baseUrl}/removeFavorite`
      : `${baseUrl}/addFavorite`;

    const requestBody = {
      userId: userInfo._id,
      statuteId: statuteItem.originalTitle,
    };

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        toast.success(
          endpoint.includes("remove")
            ? "Removed from Favorites"
            : "Added to Favorites!",
          {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Slide,
          }
        );
        const updatedStatuteNames = statuteNames.map((statute) =>
          statute.originalTitle === statuteItem.originalTitle
            ? { ...statute, isFavorite: !statute.isFavorite }
            : statute
        );
        setStatuteNames(updatedStatuteNames);
        setUserFavorites((prevFavorites) => {
          if (prevFavorites.includes(statuteItem.originalTitle)) {
            return prevFavorites.filter(
              (fav) => fav !== statuteItem.originalTitle
            );
          } else {
            return [...prevFavorites, statuteItem.originalTitle];
          }
        });

        if (favSearchResults.length > 0) {
          const updatedFavSearchResults = favSearchResults
            .map((statute) =>
              statute.title === statuteItem.title
                ? { ...statute, isFavorite: !statute.isFavorite }
                : statute
            )
            .filter((statute) => statute.isFavorite);
          if (updatedFavSearchResults?.length === 0 && confirmedSearchTerm) {
            setHasNoSearchResults(true);
          }
          setFavSearchResults(updatedFavSearchResults);
        }
        if (filteredStatutes?.length > 0 && !isFavoriteChecked) {
          const updatedFilteredStatutes = filteredStatutes.map((statute) =>
            statute.title === statuteItem.title
              ? { ...statute, isFavorite: !statute.isFavorite }
              : statute
          );
          setFilteredStatutes(updatedFilteredStatutes);
        }
        if (filteredStatutes?.length > 0 && isFavoriteChecked) {
          const updatedFilteredStatutes = filteredStatutes.map((statute) =>
            statute.title === statuteItem.title
              ? { ...statute, isFavorite: !statute.isFavorite }
              : statute
          );
          const filtered = updatedFilteredStatutes.filter(
            (statute) => statute.isFavorite
          );
          if (filtered?.length === 0) {
            setHasNoFavorites(true);
          }
          setFilteredStatutes(filtered);
        }

        // Deduct credits using the helper
        const deductionSuccess = deductCredits(
          {
            userId,
            creditsToDeduct: 1,
            event: "Toggle Favourite in Legal Library",
          },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReadNow = async (obj) => {
    try {
      setLoadingPdf((prev) => ({ ...prev, [obj?.title]: true }));
      if (obj?.url.includes("lawgptstorage")) {
        const book = await getBookPdf(obj.url);
        if (!book) {
          console.error("Failed to retrieve PDF");
          alert("Failed to retrieve the PDF. Please try again.");
          return;
        }
        const pdfBlob = new Blob([book], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      } else {
        window.open(obj.url, "_blank");
      }
    } catch (error) {
      console.error("Error retrieving PDF:", error);
    } finally {
      setLoadingPdf((prev) => ({ ...prev, [obj?.title]: false }));
    }
  };

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    if (e.target.value === "" && !isFavoriteChecked && confirmedSearchTerm) {
      getStatuteNames("");
      setHasNoSearchResults(false);
      setConfirmedSearchTerm("");
      setCurrentPage(1);
    }
    if (e.target.value === "" && isFavoriteChecked) {
      setFavSearchResults([]);
      setHasNoSearchResults(false);
      setConfirmedSearchTerm("");
      setCurrentPage(1);
    }
  };

  const handleSearch = async () => {
    if (searchTerm === "") return;
    setConfirmedSearchTerm(searchTerm);
    // Filter statutes based on search and favourite checkbox
    if (isFavoriteChecked) {
      const filteredFavResults = filteredStatutes.filter((statute) =>
        statute.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFavSearchResults(filteredFavResults);
      setHasNoSearchResults(filteredFavResults.length === 0);
      setCurrentPage(1);
    } else {
      setCurrentPage(1);
      await getStatuteNames(searchTerm);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    getStatuteNames(searchTerm);
  }, [currentPage]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f4f6f9",
        boxSizing: "border-box",
        padding: { xs: "12px", md: "24px" },
        width: "100%",
        height: "100%",
        overflowY: "auto",
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#bbb",
          borderRadius: "6px",
        },
      }}
    >
      <React.Fragment>
        <SearchCard>
          <TextField
            variant="outlined"
            placeholder="Search by title"
            fullWidth
            InputProps={{
              sx: {
                p: 0,
                backgroundColor: "#ffffff",
                borderRadius: "20px",
              },
              startAdornment: (
                <SearchIcon
                  sx={{ color: "#303f9f", padding: "8px" }}
                  fontSize={isMobileView ? "small" : "medium"}
                />
              ),
            }}
            sx={{
              flexGrow: 1,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: "#00509e",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#00509e",
                },
              },
              "& input": {
                padding: "10px",
                fontSize: "0.875rem",
              },
            }}
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
          />
          <Typography
            sx={{
              marginLeft: { xs: 1, md: 3 },
              marginRight: { xs: "1px", md: "2px" },
              fontSize: { xs: "13px", md: "14px" },
            }}
          >
            Favorites
          </Typography>
          <Checkbox
            size="small"
            sx={{ padding: { xs: 0, md: "inherit" } }}
            checked={isFavoriteChecked}
            onChange={handleCheckboxChange}
          />
        </SearchCard>
        {isStatuteNamesLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 3,
            }}
          >
            <CircularProgress size={isMobileView ? 18 : 20} />
          </Box>
        ) : (
          <ResultContainer>
            {hasNoSearchResults || hasNoFavorites ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    fontSize: { xs: "35px", lg: "50px" },
                    marginBottom: "10px",
                    color: hasNoSearchResults ? "#303f9f" : "#f39c12",
                  }}
                >
                  {hasNoSearchResults ? (
                    <SearchIcon fontSize="inherit" />
                  ) : (
                    <StarIcon fontSize="inherit" />
                  )}
                </Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: { xs: "16px", lg: "24px" },
                    marginBottom: { xs: "5px", lg: "10px" },
                  }}
                >
                  {hasNoSearchResults
                    ? "No Results Found"
                    : "No Favorites Added"}
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                  sx={{ fontSize: { xs: "12px", lg: "16px" }, color: "#666" }}
                >
                  {hasNoSearchResults
                    ? "Please review your search term or try using broader keywords."
                    : "Mark important books as favorites for quick access later."}
                </Typography>
              </Box>
            ) : (
              <>
                <Box sx={{ display: "flex", marginBottom: "8px" }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      ml: 0.5,
                      fontSize: { xs: "14px", md: "18px" },
                    }}
                  >
                    <span style={{ color: "#d2042d", fontWeight: "bold" }}>
                      {favSearchResults?.length > 0
                        ? favSearchResults.length.toLocaleString()
                        : filteredStatutes?.length > 0
                        ? filteredStatutes?.length.toLocaleString()
                        : booksCount?.toLocaleString()}
                    </span>{" "}
                    {filteredStatutes?.length > 0 ||
                    favSearchResults?.length > 0
                      ? "Books Found"
                      : "Books Available"}
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={isMobileView ? 1 : 2}
                  rowGap={isMobileView ? 1 : 2}
                >
                  {currentStatutes?.map((statute) => (
                    <Grid item xs={12} sm={6} md={3} key={statute.id}>
                      <ResultCard>
                        <CardContent
                          sx={{
                            padding: 0,
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: "#00509e",
                              fontWeight: "bold",
                              fontSize: { xs: "13px", lg: "14px" },
                              textAlign: "center",
                              backgroundColor: "#e8f0fe",
                              padding: "4px",
                              borderRadius: "4px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              lineHeight: "1.5",
                              height: "3em",
                            }}
                          >
                            {highlightText(statute.title, confirmedSearchTerm)}
                          </Typography>
                        </CardContent>
                        <CardActions
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Stack
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                            onClick={() => handleReadNow(statute)}
                          >
                            <IconButton size="small">
                              <MenuBookIcon
                                fontSize="small"
                                sx={{ color: "#7cbb00" }}
                              />
                            </IconButton>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: { xs: "13px", lg: "14px" },
                                mr: 0.5,
                                mt: { xs: 0, md: 0.5 },
                                color: "#303f9f",
                                cursor: "pointer",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              {loadingPdf[statute?.title]
                                ? "Loading..."
                                : "Read"}
                            </Typography>
                          </Stack>
                          <Stack
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                            onClick={() => toggleFavorite(statute)}
                          >
                            <IconButton size="small">
                              {statute.isFavorite ? (
                                <StarIcon
                                  fontSize="small"
                                  sx={{ color: "#ffbb00" }}
                                />
                              ) : (
                                <StarOutlineIcon
                                  fontSize="small"
                                  sx={{ color: "#ffbb00" }}
                                />
                              )}
                            </IconButton>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: { xs: "13px", lg: "14px" },
                                mt: { xs: 0, md: 0.5 },
                                color: "#303f9f",
                                cursor: "pointer",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              Favorite
                            </Typography>
                          </Stack>
                        </CardActions>
                      </ResultCard>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </ResultContainer>
        )}
        {statuteNames.length > 0 &&
          !hasNoSearchResults &&
          !hasNoFavorites &&
          !isStatuteNamesLoading && (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                display: "flex",
                justifyContent: "center",
                m: "8px 0",
                "& .MuiPaginationItem-root": {
                  fontSize: "13px",
                  minWidth: "24px",
                  height: "24px",
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: "#303f9f",
                  },
                  "&.Mui-selected": {
                    color: "#fff",
                    backgroundColor: "#213165",
                    "&:hover": {
                      backgroundColor: "#303f9f",
                    },
                  },
                },
              }}
            />
          )}
      </React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="Slide"
      />
    </Box>
  );
};

export default LegalLibrary;

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import {
  useMediaQuery,
  useTheme,
  Box,
  Stack,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Chip,
  Tooltip,
  Button,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { AddCircleOutline as AddCircleOutlineIcon } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import { logout } from "../../actions/userActions";
import DropdownButton from "../UI/DropdownButton";
import { useNavigate } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import LogoutIcon from "@mui/icons-material/Logout";
import TermsDialog from "../UI/TermsDialog";
import StarsIcon from "@mui/icons-material/Stars";
import PolicyIcon from "@mui/icons-material/Policy";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup, faStar } from "@fortawesome/free-solid-svg-icons";
import {
  hasSeenCreditsModal,
  requestCredits,
  resetCreditState,
  setCredits,
  updateCredits,
} from "../../actions/creditActions";
import PrivacyPolicyDialog from "../UI/PrivacyPolicyDialog";
import CreditsInfoModal from "../Modal/CreditsInfoModal";
import CongratulationsModal from "../Modal/CongratulationModal";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import CreditsHistoryModal from "../Modal/CreditsHistoryModal";
import FeedbackDialog from "../Modal/FeedbackDialog";
import { ToastContainer } from "react-toastify";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const Header = ({ handleSidebarToggle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [openPrivacyPolicyDialog, setOpenPrivacyPolicyDialog] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [congratsModalOpen, setCongratsModalOpen] = useState(false);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const maxRefillCount = 3;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { credits, creditRequestCount } = useSelector((state) => state.credit);

  const getFirstName = (name) => {
    return name ? name.split(" ")[0] : "";
  };

  // Function to find the AccountInfo based on homeAccountId
  const findAccountByHomeAccountId = (homeAccountId) => {
    return accounts.find((account) => account.homeAccountId === homeAccountId);
  };
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handleCongratsModalClose = () => {
    setCongratsModalOpen(false);
  };

  const handleRequestCredit = useCallback(async () => {
    if (creditRequestCount < maxRefillCount) {
      setLoading(true);
      try {
        const response = dispatch(requestCredits(userInfo._id));
        if (response?.success) {
          setCongratsModalOpen(true);
          handleClose();
          dispatch(
            updateCredits({
              credits: response.credits,
              expirationDate: response.expirationDate,
              creditRequestCount: creditRequestCount + 1,
            })
          );
          fetchUserProfile();
        }
      } catch (error) {
        console.error("Failed to request credits:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [dispatch, userInfo._id, creditRequestCount]);

  const signOutClickHandler = (instance, accountToSignOut) => {
    instance.logout({
      account: accountToSignOut,
      postLogoutRedirectUri: "/",
    });
  };

  const logOut = () => {
    const homeAccountId = accounts[0]?.homeAccountId;
    const accountToSignOut = findAccountByHomeAccountId(homeAccountId);
    if (accountToSignOut) {
      signOutClickHandler(instance, accountToSignOut);
    } else {
      console.error("Account not found for the given homeAccountId");
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTermsClick = () => {
    setOpenTermsDialog(true);
    handleClose();
  };

  const handlePrivacyPolicyClick = () => {
    setOpenPrivacyPolicyDialog(true);
    handleClose();
  };

  const handleLogout = () => {
    handleClose();
    dispatch(logout());
    dispatch(resetCreditState());
    logOut();
  };

  const getTooltipTitle = () => {
    if (credits > 20) {
      return "You have enough credits. Please consume before requesting a refill.";
    } else if (creditRequestCount >= maxRefillCount) {
      return "You've reached the maximum number of refill requests. Please contact support for assistance.";
    } else {
      return `Refill Requests: ${creditRequestCount}/${maxRefillCount}`;
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, [dispatch, userInfo?._id]);

  const fetchUserProfile = async () => {
    try {
      if (!userInfo?._id) {
        console.warn("User ID not available.");
        return;
      }

      const response = await axios.get(`${baseUrl}/getUserProfile`, {
        params: { userId: userInfo._id },
      });

      if (response.status === 200) {
        const data = response.data;
        // Dispatch actions to update the Redux store
        dispatch({
          type: "USER_LOGIN_SUCCESS",
          payload: data,
        });
        dispatch(
          setCredits(
            data.credits,
            data._id,
            data.creditExpiration,
            data.creditRequestCount
          )
        );

        dispatch(hasSeenCreditsModal(data.hasSeenModal));
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  return (
    <>
      <Box
        position="sticky"
        sx={{
          backgroundColor: "#101936",
          height: "50px",
          paddingLeft: "3.5px",
          paddingRight: "16px",
        }}
      >
        <Box
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            minHeight: "50px",
            height: "50px",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate("/home")}
          >
            <img
              style={{ width: "100px", height: "32px" }}
              src="/images/lawgpt.png"
              alt="df-logo"
            />
          </Box>
          {/* Hamburger Icon for Mobile */}
          <IconButton
            sx={{ display: { xs: "flex", lg: "none" } }}
            onClick={handleSidebarToggle}
          >
            <MenuIcon sx={{ color: "#fff" }} />
          </IconButton>
          <Stack direction="row" paddingRight="0px">
            <Tooltip
              title={"Feedback & Complaint"}
              placement="bottom"
              disableInteractive
              arrow
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "#101936",
                  },
                  "& .MuiTooltip-arrow": {
                    color: "#101936",
                  },
                },
              }}
              sx={{ display: "inline-flex" }}
            >
              <IconButton onClick={() => setOpenFeedbackDialog(true)}>
                <ContactSupportIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
            <DropdownButton />
            <Typography
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                fontSize: { xs: "12px", md: "14px" },
                marginRight: "1px",
                color: "white",
              }}
            >
              {isMobile ? getFirstName(userInfo?.name) : userInfo?.name}
            </Typography>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar
                sx={{
                  width: { xs: "25px", md: "35px" },
                  height: { xs: "25px", md: "35px" },
                  backgroundColor: "#fff",
                  color: "#101936",
                }}
                alt={userInfo?.name}
              >
                {userInfo?.name?.[0]?.toUpperCase() || "?"}
              </Avatar>
            </IconButton>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "6px",
                gap: "6px",
                borderLeft: "1px solid #CCCCCC",
              }}
            >
              <Chip
                size={isMobile ? "small" : "medium"}
                icon={
                  <FontAwesomeIcon
                    size={isMobile ? "2xs" : "lg"}
                    icon={faStar}
                    color={credits < 20 ? "#FF6347" : "#FFD700"}
                  />
                }
                label={`  ${credits || "0"}`}
                sx={{
                  color: credits < 20 ? "#FF6347" : "#FFD700",
                  fontSize: { xs: "14px", md: "16px" },
                  fontWeight: "semibold",
                  borderRadius: "6px",
                  background: credits < 20 ? "#7e3f34" : "#FFFFFF",
                  padding: "1px",
                }}
              />

              <IconButton sx={{ padding: "0px" }} onClick={handleModalOpen}>
                <InfoIcon
                  sx={{ fontSize: { xs: "1.25rem", md: "1.5rem" } }}
                  color="info"
                />
              </IconButton>
            </Box>
          </Stack>
        </Box>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            sx: {
              borderRadius: 2,
              marginTop: 1,
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              width: 250,
              "& .MuiMenuItem-root": {
                padding: "8px 16px",
                borderRadius: 1.5,
                "&:hover": {
                  backgroundColor: "#f7f7f7",
                },
              },
            },
          }}
        >
          {/* User Info Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "16px",
              backgroundColor: "#f9f9f9",
              borderBottom: "1px solid #ddd",
              marginBottom: "8px",
            }}
          >
            <Avatar
              sx={{
                width: "60px",
                height: "60px",
                backgroundColor: "#00509e",
                color: "white",
                fontSize: "24px",
                marginBottom: "12px",
              }}
            >
              {userInfo?.name?.[0]?.toUpperCase() || "U"}
            </Avatar>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#333",
                textTransform: "capitalize",
              }}
            >
              {userInfo?.name}
            </Typography>
            <Typography sx={{ fontSize: "13px", mb: "8px", color: "#555" }}>
              {userInfo?.email || userInfo?.username}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <Chip
                icon={
                  <StarsIcon
                    color={credits > 20 ? "#ff2700" : "#0073e6"}
                    fontSize="small"
                  />
                }
                label={`Available Credits: ${credits}`}
                sx={{
                  borderRadius: "16px",
                  background:
                    credits < 20
                      ? "#ff270050"
                      : "linear-gradient(90deg, #e3f2fd, #eaf4ff)",
                  color: credits < 20 ? "#ff2700" : "#00509e",
                  border:
                    credits < 20 ? "1px solid #ff2700" : "1px solid #cfe1ff",
                  fontSize: "13px",
                  fontWeight: "bold",
                  padding: "4px 8px",
                }}
              />

              <Tooltip
                title={getTooltipTitle()}
                arrow
                placement="left"
                sx={{
                  maxWidth: 220,
                  "& .MuiTooltip-tooltip": {
                    fontSize: "12px",
                    lineHeight: 1.4,
                    padding: "8px 12px",
                    textAlign: "center",
                  },
                }}
              >
                <span>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={
                      loading ? (
                        <CircularProgress size={16} sx={{ color: "#FFFFFF" }} />
                      ) : (
                        <AddCircleOutlineIcon />
                      )
                    }
                    onClick={handleRequestCredit}
                    disabled={
                      credits > 20 ||
                      creditRequestCount >= maxRefillCount ||
                      loading
                    }
                    sx={{
                      borderColor: "#50C878",
                      color: "#FFFFFF",
                      borderRadius: "16px",
                      fontSize: "13px",
                      fontWeight: "bold",
                      padding: "4px 20px",
                      backgroundColor: "#50C878",
                      width: "100%",
                      "&:hover": {
                        borderColor: "#45b269",
                        backgroundColor: "#45b269",
                      },
                      "&.Mui-disabled": {
                        backgroundColor: "#cccccc",
                        color: "#666666",
                        borderColor: "#cccccc",
                        cursor: "not-allowed",
                        pointerEvents: "auto",
                      },
                      "&.Mui-disabled:hover": {
                        backgroundColor: "#cccccc",
                      },
                    }}
                  >
                    {loading ? "Requesting..." : "Request Credits"}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
          {/* Menu Items */}
          <MenuItem
            sx={{
              minHeight: { xs: "24px", md: "48px" },
            }}
            onClick={() => {
              setModalOpen(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <WorkHistoryIcon sx={{ color: "#00509e", fontSize: "18px" }} />
            </ListItemIcon>
            <ListItemText
              primary="Credits History"
              primaryTypographyProps={{ fontSize: "13px" }}
            />
          </MenuItem>
          <MenuItem
            sx={{
              minHeight: { xs: "24px", md: "48px" },
            }}
            onClick={() => {
              navigate("/app/subscriptions");
              handleClose();
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faLayerGroup} color="#00509e" />
            </ListItemIcon>
            <ListItemText
              primary="Subscription Plans"
              primaryTypographyProps={{ fontSize: "13px" }}
            />
          </MenuItem>
          <MenuItem
            sx={{
              minHeight: { xs: "24px", md: "48px" },
            }}
            onClick={handleTermsClick}
          >
            <ListItemIcon>
              <DescriptionIcon fontSize="small" sx={{ color: "#00509e" }} />
            </ListItemIcon>
            <ListItemText
              primary="Terms and Conditions"
              primaryTypographyProps={{ fontSize: "13px" }}
            />
          </MenuItem>
          <MenuItem
            sx={{
              minHeight: { xs: "24px", md: "48px" },
            }}
            onClick={handlePrivacyPolicyClick}
          >
            <ListItemIcon>
              <PolicyIcon fontSize="small" sx={{ color: "#00509e" }} />
            </ListItemIcon>
            <ListItemText
              primary="Privacy Policy"
              primaryTypographyProps={{ fontSize: "13px" }}
            />
          </MenuItem>
          <MenuItem
            sx={{
              minHeight: { xs: "24px", md: "48px" },
            }}
            onClick={() => {
              navigate("/app/tutorial-videos");
              handleClose();
            }}
          >
            <ListItemIcon>
              <YouTubeIcon fontSize="small" sx={{ color: "#00509e" }} />
            </ListItemIcon>
            <ListItemText
              primary="Tutorial Videos"
              primaryTypographyProps={{ fontSize: "13px" }}
            />
          </MenuItem>

          <Divider sx={{ backgroundColor: "#ddd", mt: "4px" }} />
          <MenuItem
            sx={{
              minHeight: { xs: "24px", md: "48px" },
            }}
            onClick={handleLogout}
          >
            <ListItemIcon>
              <LogoutIcon fontSize="small" sx={{ color: "#d32f2f" }} />
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              primaryTypographyProps={{ fontSize: "13px" }}
            />
          </MenuItem>
        </Menu>
        <TermsDialog
          open={openTermsDialog}
          onClose={() => setOpenTermsDialog(false)}
        />
        <PrivacyPolicyDialog
          open={openPrivacyPolicyDialog}
          onClose={() => setOpenPrivacyPolicyDialog(false)}
        />
        <CreditsHistoryModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          userInfo={userInfo}
        />
      </Box>

      <CongratulationsModal
        open={congratsModalOpen}
        onClose={handleCongratsModalClose}
        credits={100}
      />
      <CreditsInfoModal open={isModalOpen} onClose={handleModalClose} />
      <FeedbackDialog
        open={openFeedbackDialog}
        onClose={() => setOpenFeedbackDialog(false)}
      />
      <ToastContainer />
    </>
  );
};

export default Header;

import React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

// Custom styled components
const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  width: "100%",
  maxWidth: "400px", // Add maximum width
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s",
  cursor: "pointer",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[6],
  },
  [theme.breakpoints.up("md")]: {
    minHeight: 400,
  },
  [theme.breakpoints.down("sm")]: {
    minWidth: "100%",
    maxWidth: "100%",
  },
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#f5f5f5" : "#2d3843",
  padding: theme.spacing(3),
  borderTop: "7px solid #003D78",
}));

const FeatureList = styled("ul")({
  margin: 0,
  padding: 0,
  listStyle: "none",
});

const Feature = styled("li")(({ theme }) => ({
  margin: theme.spacing(1, 0),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

// Plan data
const plans = [
  {
    title: "Free Plan",
    price: "Free",
    description: "Get started with basic features",
    features: [
      "50 Credits per Month",
      "1 User Seat",
      "No Client Monitoring",
      "Limited Features (Statute Chat, Case Chat, Voice Input/Output, etc.)",
    ],
    buttonText: "Coming Soon",
    buttonVariant: "outlined",
  },
  {
    title: "Standard Plan",
    price: "2,500 PKR",
    description: "Perfect for individual practitioners",
    features: [
      "500 Credits per Month",
      "1 User Seat",
      "No Client Monitoring",
      "Access to Statute Chat, Case Chat, Legal Library, and more",
    ],
    buttonText: "Coming Soon",
    buttonVariant: "contained",
  },
  {
    title: "Professional Plan",
    price: "3,500 PKR",
    description: "Ideal for small law firms",
    features: [
      "1,000 Credits per Month",
      "1 User Seat (Expandable up to 3 Clients)",
      "Client Monitoring Available",
      "Full Access: AI Drafting, Case Management, etc.",
    ],
    buttonText: "Coming Soon",
    buttonVariant: "contained",
  },
  {
    title: "Enterprise Plan",
    price: "20,000 PKR",
    description: "Custom solutions for large organizations",
    features: [
      "Unlimited Credits",
      "7 User Seats (Expandable)",
      "25 Clients Monitoring",
      "Full Access: Customization, AI Drafting, etc.",
    ],
    buttonText: "Coming Soon",
    buttonVariant: "contained",
  },
  {
    title: "Pay Per User Custom Plan",
    price: "Custom Pricing",
    description: "Build your own plan",
    features: [
      "Flexible Credits and Seats",
      "Custom Client Monitoring",
      "Full Access to All Features",
      "Customized to Your Needs",
    ],
    buttonText: "Coming Soon",
    buttonVariant: "contained",
  },
];

export default function SubscriptionPlans() {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <Container
      maxWidth={false}
      sx={{
        py: { xs: 1, lg: 3 },
        height: "calc(100vh - 50px)",
        width: { xs: "100%", lg: "calc(100% - 45px)" },
        overflowY: "auto",
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-track": {
          background: "#fff",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#bbb",
          borderRadius: "6px",
        },
      }}
    >
      <Box
        textAlign="center"
        mb={2}
        sx={{
          width: "100%",
        }}
      >
        <Typography
          component="h2"
          color="text.primary"
          gutterBottom
          sx={{
            fontSize: { xs: "18px", lg: "30px" },
            fontWeight: 700,
            color: "#303f9f",
          }}
        >
          Choose Your Plan
        </Typography>
        <Typography
          color="text.secondary"
          sx={{
            maxWidth: "800px",
            mx: "auto",
            fontSize: { xs: "14px", lg: "18px" },
          }}
        >
          Explore our plans and pick the one that best suits your legal needs.
        </Typography>
      </Box>
      <Grid
        container
        spacing={isMobileView ? 1 : 3}
        sx={{
          width: "100%",
          justifyContent: "center",
          alignItems: "stretch",
          maxWidth: "1200px",
          mx: "auto",
        }}
      >
        {plans.map((plan) => (
          <Grid
            item
            key={plan.title}
            xs={12}
            sm={6}
            md={4}
            lg={4}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <StyledCard>
              <StyledCardHeader
                title={
                  <Typography
                    component="h2"
                    sx={{
                      fontSize: { xs: "16px", lg: "18px" },
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {plan.title}
                  </Typography>
                }
                subheader={
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      color="primary"
                      sx={{
                        fontWeight: "bold",
                        fontSize: { xs: "20px", lg: "24px" },
                      }}
                    >
                      {plan.price}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{ fontSize: { xs: "14px", lg: "16px" } }}
                    >
                      per month
                    </Typography>
                  </Box>
                }
                titleTypographyProps={{ align: "center" }}
                subheaderTypographyProps={{ align: "center" }}
              />
              <CardContent sx={{ flexGrow: 1, pt: 2 }}>
                <FeatureList>
                  {plan.features.map((feature) => (
                    <Feature key={feature}>
                      <CheckIcon
                        color="primary"
                        fontSize={isMobileView ? "small" : "medium"}
                      />
                      <Typography
                        variant="body2"
                        sx={{ fontSize: { xs: "13px", lg: "14px" } }}
                      >
                        {feature}
                      </Typography>
                    </Feature>
                  ))}
                </FeatureList>
              </CardContent>
              <CardActions sx={{ p: 3, pt: 0 }}>
                <Button
                  fullWidth
                  variant="contained"
                  size={isMobileView ? "small" : "large"}
                  sx={{
                    backgroundColor: "#003D78",
                    borderRadius: 2,
                    textTransform: "none",
                    fontWeight: 600,
                    fontSize: { xs: "12px", lg: "13px" },
                    cursor: "not-allowed",
                  }}
                >
                  {plan.buttonText}
                </Button>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          mt: { xs: "1rem", lg: "3rem" },
          boxSizing: "border-box",
          borderRadius: "4px",
          padding: "8px 0",
        }}
      >
        <Typography
          textAlign="center"
          sx={{
            fontSize: { xs: "14px", lg: "16px" },
            fontStyle: "italic",
          }}
        >
          <Typography sx={{ fontWeight: "600", display: "inline" }}>
            Important Notice:
          </Typography>{" "}
          The subscription plans displayed reflect our future pricing structure.
          Current access during the beta phase is provided under temporary terms
          for evaluation purposes.
        </Typography>
      </Box>
    </Container>
  );
}

import React from "react";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledContent = styled(Box)({
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  // Style specific HTML tags
  h1: {
    fontSize: "16px",
    fontWeight: "bold",
    margin: "0.5rem 0",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  h2: {
    fontSize: "14px",
    fontWeight: "bold",
    margin: "0.4rem 0",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  p: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    fontSize: "13px !important",
    margin: "0.3rem 0",
    lineHeight: "1.5",
    color: "#333",
  },
  ul: {
    paddingLeft: "1.5rem",
    margin: "0.5rem 0",
  },
  li: {
    fontSize: "13px",
    color: "#333",
  },
  span: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "13px !important",
    color: "#333",
  },
  strong: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "13px !important",
    fontWeight: "bold",
    color: "#222",
  },
  b: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "13px !important",
    fontWeight: "bold",
    color: "#222",
  },
  div: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "13px !important",
    color: "#333",
  },
});

const DocumentPreview = ({ open, onClose, content }) => {
  const cleanQuillOutput = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    div.querySelectorAll(".ql-align-center, .ql-align-right").forEach((el) => {
      if (el.classList.contains("ql-align-center")) {
        el.style.textAlign = "center";
        el.classList.remove("ql-align-center");
      }
      if (el.classList.contains("ql-align-right")) {
        el.style.textAlign = "right";
        el.classList.remove("ql-align-right");
      }
    });
    return div.innerHTML;
  };

  const contentWithStyles = `<style>
    body {
      font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
      margin: 0 !important;
    }
    .case-details {
      width: 100% !important;
      }
    .WordSection1 {
      margin: 8px !important
    }
     
  </style> ${cleanQuillOutput(content)}`;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{
          p: { xs: 0, lg: "inherit" },
          overflowY: "auto",
          "::-webkit-scrollbar": {
            width: "5px",
          },
          "::-webkit-scrollbar-track": {
            background: "#fff",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#bbb",
            borderRadius: "6px",
          },
        }}
      >
        {!content ? (
          <Typography>No data found</Typography>
        ) : (
          <StyledContent
            sx={{ padding: 1, fontSize: "13px" }}
            dangerouslySetInnerHTML={{
              __html: contentWithStyles,
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DocumentPreview;

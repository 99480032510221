import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";

const ConfirmationModal = ({ open, onClose, onConfirm, type }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "16px",
          padding: "14px",
          background: "#fff",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: { xs: "16px", lg: "18px" },
          color: "#303f9f",
          fontWeight: "bold",
          textAlign: "center",
          paddingBottom: "12px",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        Confirm Regeneration
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          You are about to regenerate the {type}. Please note the following:
        </Typography>
        <Typography variant="body2" component="ul" sx={{ paddingLeft: 2 }}>
          <li>
            Any changes you have made in the editor will be permanently erased.
          </li>
          <li>
            All formatting adjustments you have applied will be reverted to
            their default settings.
          </li>
          <li>This action cannot be undone.</li>
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 2, fontWeight: "bold" }}>
          Do you want to proceed with the regeneration?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "16px" }}>
        <Button
          onClick={onClose}
          variant="outlined"
          color="error"
          sx={{
            borderRadius: "24px",
            padding: "8px 24px",
            textTransform: "none",
            fontWeight: "bold",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="primary"
          sx={{
            borderRadius: "24px",
            padding: "8px 24px",
            textTransform: "none",
            fontWeight: "bold",
          }}
        >
          Confirm Regeneration
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;

import React, { useState } from "react";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import BookmarksTable from "../../components/Bookmarks/BookmarksTable";
import BookmarksSidebar from "../../components/Bookmarks/BookmarksSidebar";

const Bookmarks = () => {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [selectedGroup, setSelectedGroup] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        bgcolor: "#f5f5f5",
        height: "calc(100vh - 50px)",
        width: "100%",
      }}
    >
      {isMobileView ? (
        <Drawer
          anchor="left"
          open={isSidebarOpen}
          ModalProps={{
            keepMounted: true,
          }}
          onClose={toggleSidebar}
        >
          <BookmarksSidebar
            toggleSidebar={toggleSidebar}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
          />
        </Drawer>
      ) : (
        <BookmarksSidebar
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
        />
      )}

      <BookmarksTable
        toggleSidebar={toggleSidebar}
        selectedGroup={selectedGroup}
      />
    </Box>
  );
};

export default Bookmarks;

import React from "react";
import { Box, Typography, Modal, Divider } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
const ComingSoonModal = () => {
  return (
    <Modal
      open={true}
      sx={{
        position: "absolute",
        width: { xs: "100%", lg: "calc(100% - 45px)" },
        height: "calc(100vh - 50px)",
        top: "50px",
        left: { xs: "0px", lg: "45px" },
        backdropFilter: "blur(8px)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
      BackdropProps={{
        sx: {
          position: "fixed",
          right: "0",
          bottom: "0",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "85%", lg: 400 },
          bgcolor: "background.paper",
          borderRadius: 4,
          boxShadow: 24,
          p: { xs: 2, lg: 4 },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <AccessTimeIcon
            sx={{
              fontSize: { xs: 32, lg: 64 },
              color: "#003D78",
              mb: 1,
            }}
          />
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "#003D78",
              fontSize: { xs: "20px", lg: "24px" },
            }}
          >
            Coming Soon
          </Typography>
          <Divider sx={{ width: "100%", my: 1 }} />
          <Typography
            variant="body1"
            sx={{ mb: 3, fontSize: { xs: "14px", lg: "16px" } }}
          >
            This feature is currently under development. <br />
            Stay tuned for updates!
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default ComingSoonModal;

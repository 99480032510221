import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InsightsIcon from "@mui/icons-material/Insights";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";

const StatuteDetail = ({ toggleAskChatbot, closeOnBack, selectedSection }) => {
  const itemRefs = useRef([]);
  const statuteSections = useSelector((state) => state.statuteSections);
  const { loading, sections } = statuteSections;
  const [totalMatches, setTotalMatches] = React.useState(0);
  const [currentMatch, setCurrentMatch] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightedElements, setHighlightedElements] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const highlightText = (html, search, section) => {
    //if ((!search && !section) || section === "PREAMBLE. Preamble") return html;

    const escapeRegex = (text) => text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    const escapedSearch = search ? escapeRegex(search) : null;

    // Compile regular expressions
    const regexSearch = escapedSearch
      ? new RegExp(
          `(${escapedSearch.replace(
            /\s+/g,
            "(\\s|<[^>]+>|\\n|\\r|&nbsp;|<\\/?.+?>)*"
          )})`,
          "gi"
        )
      : null;

    // Helper function for applying highlights
    const applyHighlight = (text, regex, className) => {
      return text.replace(
        regex,
        `<span class="${className}" style="background-color: ${
          className === "highlight" ? "yellow" : "lightgreen"
        }; font-weight: bold;">$1</span>`
      );
    };

    // Recursive function to apply highlights in text nodes
    const highlightNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        let highlightedText = node.textContent;
        if (regexSearch) {
          highlightedText = applyHighlight(
            highlightedText,
            regexSearch,
            "highlight"
          );
        }
        return highlightedText;
      }

      // If it's an element, recursively process its children
      if (node.nodeType === Node.ELEMENT_NODE) {
        const children = Array.from(node.childNodes)
          .map(highlightNode)
          .join("");
        const element = document.createElement(node.nodeName.toLowerCase());
        Array.from(node.attributes).forEach((attr) =>
          element.setAttribute(attr.name, attr.value)
        );
        element.innerHTML = children;
        return element.outerHTML;
      }
      return "";
    };

    // Parse the HTML string, apply highlights, and return the final HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return Array.from(tempDiv.childNodes).map(highlightNode).join("");
  };

  const handleNextMatch = () => {
    if (highlightedElements.length > 0) {
      const nextIndex = (currentIndex + 1) % highlightedElements.length;
      setCurrentIndex(nextIndex);
      setCurrentMatch(nextIndex + 1);
    }
  };

  const handlePreviousMatch = () => {
    if (highlightedElements.length > 0) {
      const prevIndex =
        (currentIndex - 1 + highlightedElements.length) %
        highlightedElements.length;
      setCurrentIndex(prevIndex);
      setCurrentMatch(prevIndex + 1);
    }
  };

  useEffect(() => {
    if (!searchTerm) {
      setCurrentIndex(-1);
      setHighlightedElements([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    // Only query highlights when searchTerm changes
    const highlights = document.querySelectorAll(".highlight");
    setHighlightedElements(Array.from(highlights));
    setTotalMatches(highlights.length);
    setCurrentMatch(highlights.length > 0 ? 1 : 0);
  }, [searchTerm]);

  useEffect(() => {
    if (highlightedElements.length > 0) {
      highlightedElements.forEach((el, index) => {
        if (index === currentIndex) {
          el.style.backgroundColor = "orange";
          el.scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          el.style.backgroundColor = "yellow";
        }
      });
    }
  }, [currentIndex, highlightedElements]);

  useEffect(() => {
    // Scroll to the specific index if the selectedSection is valid
    if (selectedSection !== null && itemRefs.current[selectedSection]) {
      itemRefs.current[selectedSection].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedSection]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flex: 5,
        flexGrow: 1,
        boxSizing: "border-box",
        bgcolor: "#f5f5f5",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "98%",
          bgcolor: "#f5f5f5",
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", lg: "none" },
          }}
        >
          <IconButton
            sx={{ color: "#303f9f", paddingRight: "0px" }}
            onClick={closeOnBack}
          >
            <FontAwesomeIcon icon={faCircleArrowLeft} fontSize="23px" />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            backgroundColor: "white",
            margin: { xs: "8px", lg: "8px 18px 8px 18px" },
            borderRadius: "24px",
            "& .MuiInputBase-root": {
              height: "40px",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "24px",
            },
            "& input": {
              padding: "10px",
              fontSize: "0.875rem",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#303f9f" }} />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <Typography variant="body2" sx={{ mr: 1 }}>
                  {currentMatch}/{totalMatches}
                </Typography>
                <IconButton
                  size="small"
                  onClick={handlePreviousMatch}
                  disabled={totalMatches === 0}
                >
                  <KeyboardArrowUpIcon
                    fontSize="small"
                    sx={{ color: "#303f9f" }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={handleNextMatch}
                  disabled={totalMatches === 0}
                >
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    sx={{ color: "#303f9f" }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: { xs: "flex", lg: "none" } }}>
          <IconButton
            sx={{ color: "#303f9f", paddingLeft: "0px" }}
            onClick={toggleAskChatbot}
          >
            <InsightsIcon />
          </IconButton>
        </Box>
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "24px",
            width: "100%",
          }}
        >
          <CircularProgress
            size={20}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            boxSizing: "border-box",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
            bgcolor: "#f5f5f5",
            p: "8px 12px",
            m: "i8px 8px 8px 0",
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#bbb",
              borderRadius: "6px",
            },
          }}
        >
          {sections?.[0]?.related_data
            ?.sort((a, b) => {
              if (a.Section === "PREAMBLE") return -1;
              if (b.Section === "PREAMBLE") return 1;
              const sectionA = parseFloat(a.Section);
              const sectionB = parseFloat(b.Section);
              return sectionA - sectionB;
            })
            .map((result, index) => (
              <Box
                key={index}
                ref={(el) => (itemRefs.current[index] = el)}
                sx={{
                  p: { xs: 1, lg: "8px 0px 8px 32px" },
                  display: "flex",
                  flexDirection: "column",
                  mb: { xs: 1, lg: 2 },
                  bgcolor: "#fff",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{ p: 1, fontSize: { xs: "12px", md: "13px" } }}
                  dangerouslySetInnerHTML={{
                    __html: highlightText(
                      result.Statute_HTML,
                      searchTerm,
                      selectedSection
                    ),
                  }}
                />
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default StatuteDetail;
